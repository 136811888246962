<template>
	<div :class="['drawer',{'drawer--open' : open},]">
		<div class="drawer__scroll">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		open: Boolean,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.drawer{
	background: var(--drawer-background);
	position: fixed;
	z-index: 10;
	top: var(--drawer-top);
	left: var(--drawer-left);
	padding: var(--drawer-padding);
	transition:  var(--drawer-transition);
	box-shadow: var(--drawer-box-shadow);
	width: var(--drawer-width);
	height: var(--drawer-height);
	overflow: hidden;
}
.drawer__scroll{
	overflow-y: scroll;
	height: var(--drawer-container-height);
	width: var(--drawer-container-width);
	padding: var(--drawer-container-padding);
}
.drawer--open{
	left: var(--drawer-open-left);
}
</style>

<!-- Used in : DS, SD -->